function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: "/admin",
  app: "/",
  docs: "/docs",
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: "/login",
    register: path(ROOTS.auth, "/register"),
    registerUnprotected: path(ROOTS.auth, "/register-unprotected"),
    loginUnprotected: path(ROOTS.auth, "/login-unprotected"),
    resetPassword: path(ROOTS.auth, "/reset-password"),
  },
  error404: "/404",
  error500: "/500",
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
};

export const PATH_HOME = {
  components: "/components",
  cloud: "https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0",
  purchase: "https://material-ui.com/store/items/minimal-dashboard/",
  dashboard: ROOTS.app,
};

export const PATH_APP = {
  root: ROOTS.app,

  main: {
    root: path(ROOTS.app, "dashboard"),
    dashboard: path(ROOTS.app, "dashboard"),
    ecommerce: path(ROOTS.app, "dashboard/ecommerce"),
    analytics: path(ROOTS.app, "dashboard/analytics"),
  },
  blueprints: {
    root: path(ROOTS.app, "admin/blueprints"),
  },
  cards: {
    root: path(ROOTS.app, "admin/cards"),
  },
  templateUpload: {
    root: path(ROOTS.app, "admin/template-upload"),
  },
};

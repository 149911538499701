import React from "react";
import { PATH_APP } from "routes/paths";

import { Icon } from "@chakra-ui/react";
import { MdHome, MdOutlineShoppingCart, MdBook, MdFilePresent, MdSettings } from "react-icons/md";

export const pageHeaderName = {
  [PATH_APP.main.dashboard]: "Dashboards",
  [PATH_APP.blueprints.root]: "Blueprints",
  [PATH_APP.templateUpload.root]: "Template Upload",
};

const routes = [
  // --- Dashboards ---
  {
    name: "Dashboards",
    path: "dashboard",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    collapse: false,
  },
  {
    name: "Blueprints",
    path: "admin/blueprints",
    icon: <Icon as={MdFilePresent} width="20px" height="20px" color="inherit" />,
    collapse: false,
  },
  {
    name: "Cards",
    path: "admin/cards",
    icon: <Icon as={MdBook} width="20px" height="20px" color="inherit" />,
    collapse: false,
  },
  {
    name: "Templates",
    path: "admin/template-upload",
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    collapse: false,
  },
  // {
  //   name: "Tours",
  //   path: "tours",
  //   icon: <Icon as={MdTravelExplore} width="20px" height="20px" color="inherit" />,
  //   collapse: false,
  // },
];

export default routes;
